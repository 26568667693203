import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Popover,
  MenuItem,
  
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Stack,
  Tooltip,
  
  Chip,
  FormControl,
  Select,
  Box,
  Switch,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { log } from "../../../data/configs/utils";
import * as _ from "lodash";
import {
  deleteAction,
  postCreateAction,
  updateAction,
} from "../../../data/store/assistantsSlice";
import TransferToAgentDialog from "./TransferToAgentDialog";
import WebhookDialog from "./WebhookDialog";
import BookAMeetingMsg from "../../BookAMeetingMsg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ReadOnlyDataDialog from "./ReadOnlyDataDialog";
import TransferToAgentSelectDialog from "./TransferToAgentSelectDialog";
import WebhookSelectDialog from "./WebhookSelectDialog";
import CustomHintPopover from "../../CustomHintPopover";
import { THEME_MODE } from "../../../data/configs/constants";
const AssistantFunctions = ({
  handleSaveChanges,
  setCallAllActionsApi,
  handleFetchAllActionData,
  handleChangeAssistantChangedData,
}) => {
  const dispatch = useDispatch();
  const assistantAllActionIds = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.actions
        ?.assistantAllActionIds
  );

  const transferToAgent = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo?.transferToAgent
  );
  const webhook = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo?.webhook
  );

  const customSMS = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.customSMS
  );
  const appointmentScheduling = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.appointmentScheduling
  );

  const transferToAgentActions = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.actions?.transfer_to_agent
        ?.usedAssistantActions
  );
  const transferToAgentActionsToChoose = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.actions?.transfer_to_agent
        ?.availableAssistantActions
  );
  const transferToAgentActionsAvailableQuota = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.actions?.transfer_to_agent
        ?.availableQuota
  );

  const webhookActions = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.actions?.webhook
        ?.usedAssistantActions
  );
  const webhookActionsToChoose = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.actions?.webhook
        ?.availableAssistantActions
  );
  const webhookActionsAvailableQuota = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.actions?.webhook
        ?.availableQuota
  );

  const smsActions = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.actions
        ?.custom_sms?.usedAssistantActions
  );

  
  const meetingActions = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.actions
        ?.custom_sms?.usedAssistantActions
  );
  const assistant_appointment_scheduling_ids = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.actions
        ?.assistant_appointment_scheduling_ids
  );

  const customSMSToChoose=useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.actions
        ?.custom_sms?.availableAssistantActions
  );

  // const meetingActionsToChoose = useSelector(
  //   (state) =>
  //     state.assistantsDetails?.assistantInitialInfo?.actions
  //       ?.appointment_scheduling?.availableAssistantActions
  // );

  const [tempMessageName,setTempMessageName]=useState('')
  const [tempConsentMessage,setTempConsentMessage]=useState('')

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMsgTemplateOpen, setSelectedMsgTemplateOpen] = useState(false);
  const [currentMsgTemplateId, setCurrentMsgTemplateId] = useState("");
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const [actionViewDialogOpen, setActionViewDialogOpen] = useState(false);
  const [actionViewObj, setActionViewObj] = useState({});
  const [webhookDialogOpen, setWebhookDialogOpen] = useState(false);
  const [meetingDialogOpen, setMeetingDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [webhookUrlArray, setWebhookUrlArray] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [actionToDelete, setActionToDelete] = useState(null);
  const themeMode = useSelector((state) => state.pageDetails.themeMode);
  const [tempCustomSMSFlag,setTempCustomSMSFlag]=useState(true)
  const [tempscheduleMeetingButtonFlag,setTempScheduleMeetingButtonFlag]=useState(false)

  const defaultFormData = {
    templateName: "",
    calendarSpecificInfo: {
      calendar: "cal",
      eventId: "",
      accountUserId: "",
      meetingLocation: {
        type: "",
        identifier: "",
      },
      attendeeTimeZone: "",
    },
    communicationChannelInfo: {
      channel: "sms",
     // meetingLink: "",
      messageBody:"",
       
    //  consentMessage: `We'll send you a link via SMS to book a meeting with us. Reply 'STOP' to the message to opt-out,Would you like to receive the SMS ?`,
      serviceInfo: {
        // phoneNumberId: "",
        provider: "twilio",
        // accountId: "",
        // authToken: ""
      },
    },
    title: "",
    description: "",
    meetingGuests: [],
  };

  const [transferToAgentAccordianToggle, setTransferToAgentAccordianToggle] =
    useState(transferToAgent ?? false);
  const [webhookAccordianToggle, setWebhookAccordianToggle] = useState(
    webhook ?? false
  );
  const [customSmsToggle, setCustomSmsToggle] =
  useState(customSMS ?? false);
  const [bookAMeetingAccordianToggle, setBookAMeetingAccordianToggle] =
    useState(appointmentScheduling ?? false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [formData, setFormData] = useState(defaultFormData);
  const [
    isOpenTransferToAgentSelectDialog,
    setIsOpenTransferToAgentSelectDialog,
  ] = useState(false);
  const [isOpenWebhookSelectDialog, setIsOpenWebhookSelectDialog] =
    useState(false);
  const [isOpenBookAMeetingSelectDialog, setIsOpenBookAMeetingSelectDialog] =
    useState(false);
  const [formDataToUpdate, setFormDataToUpdate] = useState({
    id: "",
    formData: defaultFormData,
    triggeringPrompts: ["I'm interested in scheduling a meeting."],
  });

  useEffect(() => {
    const combinedUrls = [
      ...(webhookActions || []),
      ...(webhookActionsToChoose || []),
    ].map((item) => item.data.url);
    setWebhookUrlArray(combinedUrls);
  }, [webhookActions, webhookActionsToChoose]);

  useEffect(() => {
    setTransferToAgentAccordianToggle(transferToAgent ?? false);
  }, [transferToAgent]);
  useEffect(() => {
    setWebhookAccordianToggle(webhook ?? false);
  }, [webhook]);
  useEffect(() => {
    setCustomSmsToggle(customSMS ?? false);
  }, [customSMS]);
  useEffect(() => {
    setBookAMeetingAccordianToggle(appointmentScheduling ?? false);
  }, [appointmentScheduling]);

  const handleFormDataChange = (parent, name, value) => {
    if (parent === "root") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else
      setFormData((prevData) => ({
        ...prevData,
        [parent]: {
          ...prevData[parent],
          [name]: value,
        },
      }));
  };

  const trimStringFields = (obj) => {
    const newObj = Array.isArray(obj) ? [] : {};
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        newObj[key] = obj[key].trim();
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        newObj[key] = trimStringFields(obj[key]);
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };

  const [triggerPrompts, setTriggerPrompts] = useState([
    "I'm interested in scheduling a meeting.",
  ]);

  const createAction = (mode) => {
    const filteredData = { ...formData };
    if (mode === "communication") delete filteredData.calendarSpecificInfo;
    if (mode === "calendar") delete filteredData.communicationChannelInfo;

    // Trim all string fields in filteredData
    const trimedData = trimStringFields(filteredData);

    const payload = {
      name: "Custom_SMS",
      description: "Custom SMS for user",
      type: "custom_sms",
      data: {
        ...{...trimedData,communicationChannelInfo:{...trimedData.communicationChannelInfo,consentMessage:tempConsentMessage}},
        mode,
      },
      triggerPrompts,
    };
    dispatch(postCreateAction({ ...payload }))
      .unwrap()
      .then((res) => {
        if (res?.data?.action?._id && assistantAllActionIds) {
          handleSaveChanges(
            true,
            {
              actions: [...assistantAllActionIds, res?.data?.action?._id],
            },
            true
          );
        }
        setMeetingDialogOpen(false);
        setEditMsgTemplate(false);
        handleSetPayloadDataAndFormDataToInitialValue();
      })
      .catch((err) => {
        log("ERR-getAllActions", err);
      });

    // handleAddWebhookDialogClose();
    // setSelectedOpen(false);
  };

  const handleDeleteAction = (id) => {
    dispatch(deleteAction({ id }))
      .unwrap()
      .then((res) => {
        handleFetchAllActionData();
      })
      .catch((err) => {
        log("ERR-getAllActions", err);
      });
  };

  const updatingAction = (mode) => {
    const filteredData = { ...formData };
    if (mode === "communication") delete filteredData.calendarSpecificInfo;
    if (mode === "calendar") delete filteredData.communicationChannelInfo;

    // Trim all string fields in filteredData
    const trimedData = trimStringFields(filteredData);

    const payload = {
      name: "Custom SMS",
      description: "Custom SMS for user",
      type: "custom_sms",
      data: {
        ...trimedData,
        mode,
      },
      triggerPrompts,
      id: currentMsgTemplateId,
    };
    dispatch(updateAction({ ...payload }))
      .unwrap()
      .then((res) => {
        handleFetchAllActionData();
        handleSetPayloadDataAndFormDataToInitialValue();
        setEditMsgTemplate(false);
        setMeetingDialogOpen(false);
      })
      .catch((err) => {
        log("ERR-getAllActions", err);
      });
  };

  // const handleClick = (event) => setAnchorEl(event.currentTarget);
  // const handleClose = () => setAnchorEl(null);
  // const openPopover = Boolean(anchorEl);

  const handleOptionClick = (option) => {
    if (option === "Transfer to Agents") {
      setTransferDialogOpen(true);
    } else if (option === "Webhook") {
      setWebhookDialogOpen(true);
    } else if (option === "Book a Meeting") {
      setMeetingDialogOpen(true);
    }
    handleClose();
  };

  const handleDialogClose = () => {
    setTransferDialogOpen(false);
    setWebhookDialogOpen(false);
    setMeetingDialogOpen(false);
  };

  const handleCreateAction = (type, data) => {
    setIsLoading(true);
    dispatch(
      postCreateAction({
        type,
        label: data.label,
        data,
        triggerPrompts: [],
        name: type === "webhook" ? "Webhook" : "Transfer to Agents",
        description: data.description,
      })
    )
      .unwrap()
      .then((res) => {
        if (res?.data?.action?._id && assistantAllActionIds) {
          handleSaveChanges(
            true,
            {
              actions: [...assistantAllActionIds, res?.data?.action?._id],
            },
            true
          );
        }
      })
      .catch((err) => {
        log("ERR-getAllActions", err);
      })
      .finally(() => setIsLoading(false));
  };

  const handleAddFromExistingActions = (id) => {
    handleSaveChanges(
      true,
      {
        actions: [...assistantAllActionIds, id],
      },
      true
    );
  };

  const handleDeleteActionFromCurrentAssistant = (id) => {
    // Clone the array to avoid mutating the original
    const newIdsArray = assistantAllActionIds.filter(
      (actionId) => actionId !== id
    );

    // Save the changes with the updated action array
    handleSaveChanges(
      true,
      {
        actions: newIdsArray, // New array without the deleted id
      },
      true
    );
    setTempConsentMessage(' ')
  };

  const findIndex = (id, array) => {
    return array.findIndex((item) => item === id);
  };
  const handleAddFromExistingBookAMeetingActions = (id) => {
    let indexToRemove;
    let newIdsArray = _.cloneDeep(assistantAllActionIds) || [];
    if (assistant_appointment_scheduling_ids?.length >= 1) {
      indexToRemove = findIndex(
        assistant_appointment_scheduling_ids[0],
        assistantAllActionIds
      );
      if (indexToRemove === -1) newIdsArray.push(id);
      else newIdsArray[indexToRemove] = id;
      handleSaveChanges(
        true,
        {
          actions: [...newIdsArray],
        },
        true
      );
    } else {
      handleSaveChanges(
        true,
        {
          actions: [...assistantAllActionIds, id],
        },
        true
      );
    }
    
    setSelectedMsgTemplateOpen(false);
    setIsOpenBookAMeetingSelectDialog(false);
    setTempConsentMessage('')
  };

  const handleAddDeleteTriggerPrompts = (operation, value) => {
    if (!operation || !value) return;

    const updatedValue = value.trim();
    const triggerPromptsArray = Array.isArray(triggerPrompts)
      ? triggerPrompts
      : [];
    const doesExist = triggerPromptsArray.includes(updatedValue);

    if (operation === "add" && !doesExist) {
      setTriggerPrompts([updatedValue, ...triggerPromptsArray]);
    } else if (operation === "delete") {
      const updatedTriggerPrompts = triggerPromptsArray.filter(
        (val) => val !== updatedValue
      );
      setTriggerPrompts(updatedTriggerPrompts);
    }
  };

  const handleDeleteConfirm = (id) => {
    setActionToDelete(id);
    setDeleteDialogOpen(true);
  };

  const confirmDeleteAction = () => {
    // Call delete function here with actionToDelete
    setDeleteDialogOpen(false);
  };

  const handleSetPayloadDataAndFormDataToInitialValue = () => {
    setFormData(defaultFormData);
    setTriggerPrompts(["I'm interested in scheduling a meeting."]);
    setCurrentMsgTemplateId("");
  };

  const findObjectById = (id, array) => {
    return array.find((item) => item._id === id);
  };

  const handleViewData = (id, type) => {
    let foundObject = {};
    if (type === "transfer_to_agent") {
      foundObject = findObjectById(id, transferToAgentActions);
    }
    if (type === "webhook") {
      foundObject = findObjectById(id, webhookActions);
    }

    setActionViewObj(foundObject);
    setActionViewDialogOpen(true);
  };
  const handleViewEditBookAMeeting = (id) => {
    let foundObject = {};
    foundObject = findObjectById(id, meetingActions);
    setMeetingDialogOpen(true);
    setFormData(foundObject?.data);
    setTriggerPrompts(foundObject?.triggerPrompts);
    setCurrentMsgTemplateId(id);
    setEditMsgTemplate(true);
  };

  const handleAddExistingBookAMeetingDialogBox = (item) => {
    setCurrentMsgTemplateId(item?._id ?? "");
    setSelectedTemplate({
      id: item._id,
      data: { ...item.data },
      triggerPrompts: item.triggerPrompts,
    });
    setSelectedMsgTemplateOpen(true);
  };

  const [editMsgTemplate, setEditMsgTemplate] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  // const renderActionItems = (actions) =>
  //   actions?.map((item) => (
  //     <Grid
  //       container
  //       item
  //       xs={11.5}
  //       key={item._id}
  //       sx={{
  //         margin: "4px 0",
  //         paddingInline: 1,
  //         borderRadius: "5px",
  //         backgroundColor: "#E1E4E9",
  //       }}
  //     >
  //       <Stack
  //         direction="row"
  //         justifyContent="space-between"
  //         alignItems="center"
  //         width="100%"
  //       >
  //         <Typography variant="body2">
  //           {item?.label?.length > 0
  //             ? item.label
  //             : item.data.templateName ?? item.name}
  //         </Typography>
  //         <Stack direction="row" spacing={1}>
  //           {item.type &&
  //           (item.type === "transfer_to_agent" || item.type === "webhook") ? (
  //             <Tooltip title="View" arrow placement="top">
  //               <IconButton
  //                 size="small"
  //                 aria-label="edit"
  //                 onClick={() => handleViewData(item._id, item.type)}
  //               >
  //                 <VisibilityOutlinedIcon fontSize="small" />
  //               </IconButton>
  //             </Tooltip>
  //           ) : (
  //             <Tooltip title="Edit" arrow placement="top">
  //               <IconButton
  //                 size="small"
  //                 aria-label="edit"
  //                 onClick={() => handleViewEditBookAMeeting(item._id)}
  //               >
  //                 <EditIcon fontSize="small" />
  //               </IconButton>
  //             </Tooltip>
  //           )}

  //           <Tooltip title="Delete" arrow placement="top">
  //             <IconButton
  //               size="small"
  //               aria-label="delete"
  //               onClick={() => handleDeleteAction(item._id)}
  //             >
  //               <DeleteIcon fontSize="small" />
  //             </IconButton>
  //           </Tooltip>
  //         </Stack>
  //       </Stack>
  //     </Grid>
  //   ));
  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "delete-popover" : undefined;
  const renderActionItems = (actions) => {
    return actions?.map((item) => (
      <Grid
        container
        item
        xs={11.5}
        key={item._id}
        sx={{
          margin: "4px 0",
          paddingInline: 1,
          borderRadius: "5px",
          backgroundColor: "#E1E4E9",
          color: "themetext.primary"
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant="body2" color= {"black"}>
            {item?.label?.length > 0
              ? item.label
              : item.data.templateName ?? item.name}
          </Typography>
          <Stack direction="row" spacing={1}>
            {item.type &&
            (item.type === "transfer_to_agent" || item.type === "webhook") ? (
              <Tooltip title="View" arrow placement="top">
                <IconButton
                  size="small"
                  aria-label="edit"
                  onClick={() => handleViewData(item._id, item.type)}
                  sx={{ color: "black"}}
                >
                  <VisibilityOutlinedIcon  fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Edit" arrow placement="top">
                <IconButton
                  size="small"
                  aria-label="edit"
                  onClick={() => handleViewEditBookAMeeting(item._id)}
                  sx={{ color: "black"}}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                size="small"
                aria-label="delete"
                onClick={(event) => handleClick(event, item)}
                sx={{ color: "black"}}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>

        {/* Popover for delete options */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: "10px",
              },
            },
          }}
        >
          <Box sx={{ padding: 2 }}>
            <Typography sx={{ marginBottom: 2, textAlign: "center" }}>
              Choose delete option:
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Button
                size="small"
                sx={{
                  textAlign: "start",
                  textTransform: "none",
                  borderRadius: "5px",
                }}
                variant="outlined"
                onClick={() => {
                  handleDeleteActionFromCurrentAssistant(selectedItem._id);
                  handleClose();
                }}
                endIcon={<DeleteIcon fontSize="small" />}
              >
                Delete from Current Assistant
              </Button>
              <Button
                size="small"
                sx={{
                  textAlign: "start",
                  textTransform: "none",
                  borderRadius: "5px",
                }}
                color="error"
                variant="outlined"
                onClick={() => {
                  handleDeleteAction(selectedItem._id);
                  handleClose();
                }}
                endIcon={<DeleteIcon fontSize="small" color="error" />}
              >
                Delete from All Assistants
              </Button>
            </Box>
          </Box>
        </Popover>
      </Grid>
    ));
  };

  return (
    <div>
      <Grid
        container
        item
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={11.5}>
          <Typography variant="caption">
            Enable your agent with capabilities such as calendar bookings, call
            termination...
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          mt={1}
          display="flex"
          justifyContent="center"
        >
          {transferToAgentActions?.length ||
          webhookActions?.length ||
          meetingActions?.length ? null : (
            <Typography variant="body2" color="textSecondary" align="center">
              No actions added. Click "Add" to create a new action.
            </Typography>
          )}
          <>
            <Grid
              item
              xs={12}
              mt={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                display="flex"
                alignItems="center"
                variant="body2"
                fontWeight="bold"
              >
                Transfer to Human
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  // hintTitle={'Bot Temperature Setting'}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"bottom"}
                  anchorHorizontalPosition={"right"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    "During call if the contact wishes to talk to human, we will forward the call to these number(s). The call will be tried with all the number(s) but only connected with the first agent who picks up the call."
                  }
                />
              </Typography>
              <Switch
                checked={transferToAgentAccordianToggle}
                onChange={(e) => {
                  handleChangeAssistantChangedData({
                    transferToAgent: e.target.checked,
                  });
                  setTransferToAgentAccordianToggle(e.target.checked);
                }}
              />
            </Grid>
            {renderActionItems(transferToAgentActions)}

            <Grid item xs={11.5} mt={2}>
              <Button
                size="small"
                variant="outlined"
                sx={{ borderRadius: "8px", textTransform: "none" }}
                startIcon={<AddIcon />}
                onClick={() => {
                  setIsOpenTransferToAgentSelectDialog(true);
                }}
              >
                Add
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              mt={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                display="flex"
                alignItems="center"
                variant="body2"
                fontWeight="bold"
              >
                Webhook
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"bottom"}
                  anchorHorizontalPosition={"right"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    <>
                      <p>
                        Enter a POST HTTP endpoint to receive post call data on.
                        This can be any POST webhook url from services like
                        Zapier, goHighLevel, your personal service endpoint etc.
                      </p>
                      <br />
                      <p>
                        <strong>Use Cases :</strong> Can be used for meeting
                        bookings, Mail/Message(s) sending, etc.
                      </p>
                    </>
                  }
                />
              </Typography>
              <Switch
                checked={webhookAccordianToggle}
                onChange={(e) => {
                  handleChangeAssistantChangedData({
                    webhook: e.target.checked,
                  });
                  setWebhookAccordianToggle(e.target.checked);
                }}
              />
            </Grid>
            {renderActionItems(webhookActions)}
            <Grid item xs={11.5} mt={2}>
              <Button
                size="small"
                variant="outlined"
                sx={{ borderRadius: "8px", textTransform: "none" }}
                startIcon={<AddIcon />}
                onClick={() => {
                  setIsOpenWebhookSelectDialog(true);
                }}
              >
                Add
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              mt={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                display="flex"
                alignItems="center"
                variant="body2"
                fontWeight="bold"
              >
                Custom SMS
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"bottom"}
                  anchorHorizontalPosition={"right"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    <>
                      <p>
                      Set up customizable trigger prompts like "Send me a brochure" or "Can we set up an appointment?" during a call. When these prompts are spoken, an SMS with your predefined message in the template will be sent automatically.
                      </p>
                     
                    </>
                  }
                />
                {/* <CustomHintPopover
                  
                  showIcon={false}
                  size="small"
                  maxWidth={400}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"bottom"}
                  anchorHorizontalPosition={"right"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    <>
                      <p>
                        This feature ensures a smooth and efficient way to book
                        your meetings, making the process as seamless and
                        convenient as possible.
                      </p>
                      <br />
                      <p>
                        <strong>SMS Booking</strong>
                      </p>
                      <ul>
                        <li>
                          <p>
                            <strong>What it Does:</strong> On a call, if a user
                            wants to book a meeting, they will receive an SMS
                            with a scheduling link.
                          </p>
                        </li>
                        <br />
                        <li> */}
                          {/* <p>
                            <strong>Configuration :</strong> Customize the
                            message body, provide the scheduling link, and set
                            specific phrases (trigger prompts) that will
                            automatically initiate the SMS booking flow during
                            the call.
                          </p>
                        </li>
                      </ul>
                      <br />
                      <p>
                        <strong>
                          Note: This feature is currently in Beta.
                        </strong>
                      </p>
                    </>
                  } */}
                {/* /> */}
              </Typography>
              <Switch
                checked={customSmsToggle}
                onChange={(e) => {
                  handleChangeAssistantChangedData({
                    customSMS: e.target.checked,
                  });
                  setCustomSmsToggle(e.target.checked);
                }}
              />
            </Grid>
          
            {renderActionItems(meetingActions)}
          </>
        </Grid>

        <Grid item xs={11.5} mt={2}>
        { meetingActions?.length<1 && <Button
            size="small"
            variant="outlined"
            sx={{ borderRadius: "8px", textTransform: "none" }}
            startIcon={meetingActions?.length >= 1 ? "" : <AddIcon />}
            onClick={() => {
              if (
             //   meetingActionsToChoose?.length === 0 &&
             customSMSToChoose?.length===0 &&
                meetingActions?.length === 0
              ) {
                setMeetingDialogOpen(true);
              } else {
                setIsOpenBookAMeetingSelectDialog(true);
              }
            }}
          >
            Add
             {/* {meetingActions?.length < 1 ? "Add" : ""}  */}
          </Button>}
        </Grid>
      </Grid>

      {/* <Button
                  onClick={() => {
                    if (
                      filteredBookAMeetingActionData?.length === 0 &&
                      initialBookAMeetingTemplate?.length === 0
                    ) {
                      setSelectedOpen(true);
                    } else {
                      setOpenAddNumberDialog(true);
                    }
                  }}
                  variant="contained"
                  //   startIcon={<AddIcon />}
                  disabled={initialBookAMeetingTemplate?.length >= 2}
                  sx={{
                    width: "200px",
                    marginBlock: 4,
                    borderRadius: "10px !important",
                  }}
                >
                  {initialBookAMeetingTemplate?.length === 0
                    ? "Add Template"
                    : "Change Template"}
                </Button> */}

      <TransferToAgentDialog
        open={transferDialogOpen}
        onClose={handleDialogClose}
        TransferToActionCreateAction={(number, label, description) =>
          handleCreateAction("transfer_to_agent", {
            phoneNumbers: [number],
            label,
            description,
          })
        }
      />

      <TransferToAgentSelectDialog
        isOpen={isOpenTransferToAgentSelectDialog}
        onClose={() => setIsOpenTransferToAgentSelectDialog(false)}
        availableAgentQuota={transferToAgentActionsAvailableQuota}
        availableToChooseTransferToAgentActionsArray={
          transferToAgentActionsToChoose
        }
        handleOptionClick={handleOptionClick}
        handleAddFromExistingTransferToAgent={handleAddFromExistingActions}
      />

      <WebhookDialog
        open={webhookDialogOpen}
        onClose={handleDialogClose}
        webhookUrlArray={webhookUrlArray}
        WebhookCreateAction={(url, label, description) =>
          handleCreateAction("webhook", { url, label, description })
        }
      />

      <WebhookSelectDialog
        isOpen={isOpenWebhookSelectDialog}
        onClose={() => setIsOpenWebhookSelectDialog(false)}
        availableAgentQuota={webhookActionsAvailableQuota}
        availableToChooseWebhookActionsArray={webhookActionsToChoose}
        handleOptionClick={handleOptionClick}
        handleAddFromExistingWebhook={handleAddFromExistingActions}
      />

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this action?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDeleteAction} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        PaperProps={{
          style: {
            maxWidth: "1000px",
            width: "900px",
          },
        }}
        open={meetingDialogOpen}
        onClose={() => {
          setMeetingDialogOpen(false);
          setEditMsgTemplate(false);
          handleSetPayloadDataAndFormDataToInitialValue();
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <BookAMeetingMsg
            handleSetPayloadDataAndFormDataToInitialValue={
              handleSetPayloadDataAndFormDataToInitialValue
            }

            tempMessageName={tempMessageName}
            setTempMessageName={setTempMessageName}
            tempConsentMessage={tempConsentMessage}
            setTempConsentMessage={setTempConsentMessage}

            tempCustomSMSFlag={tempCustomSMSFlag}
            setTempCustomSMSFlag={setTempCustomSMSFlag}
            tempscheduleMeetingButtonFlag={tempscheduleMeetingButtonFlag}
            setTempScheduleMeetingButtonFlag={setTempScheduleMeetingButtonFlag}
            setEditMsgTemplate={setEditMsgTemplate}
            setSelectedOpen={setMeetingDialogOpen}
            editMsgTemplate={editMsgTemplate}
            handleAddDeleteTriggerPrompts={handleAddDeleteTriggerPrompts}
            triggerPrompts={triggerPrompts}
            createAction={createAction}
            handleFormDataChange={handleFormDataChange}
            formData={formData}
            setFormData={setFormData}
            updatingAction={updatingAction}
          />
        </DialogContent>
      </Dialog>

      <ReadOnlyDataDialog
        open={actionViewDialogOpen}
        onClose={() => {
          setActionViewDialogOpen(false);
        }}
        data={actionViewObj}
        type={actionViewObj?.type} // or "webhook" depending on the use case
      />

      <Dialog
        PaperProps={{
          style: {
            maxWidth: "1400px",
          },
        }}
        open={isOpenBookAMeetingSelectDialog}
        onClose={() => setIsOpenBookAMeetingSelectDialog(false)}
      >
        <DialogTitle>
          <Typography align="center" variant="h6" fontWeight="bold">
            {meetingActions?.length >= 1 ? "Change Template" : "Add Template"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setIsOpenBookAMeetingSelectDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container sx={{ minWidth: "550px" }}>
            <Grid item xs={12} align="center" mt={2} mb={2}>
              <Tooltip
                title={
                  meetingActions?.length >= 1
                    ? "You can add maximum 1 MSG Template. Delete current to add another."
                    : ""
                }
                arrow
                placement="top"
              >
                <span>
                  <Button
                    style={{
                      minWidth: "300px",
                      paddingBlock: 4,
                      paddingInline: 15,
                    }}
                    variant="contained"
                    disabled={meetingActions?.length >= 1}
                    onClick={() => {
                      handleOptionClick("Book a Meeting");
                      setIsOpenBookAMeetingSelectDialog(false);
                    }}
                  >
                    <Typography
                      align="center"
                      variant="body1"
                      textTransform="none"
                      sx={{ color: "black"}}
                    >
                       Custom SMS
                    </Typography>
                  </Button>
                </span>
              </Tooltip>
            </Grid>
            {/* {meetingActionsToChoose?.length !== 0 && ( */}
            { customSMSToChoose?.length !==0 && (
              <Grid item xs={12} align="center" mt={2} mb={2}>
                <Typography align="center" fontWeight="bold" variant="h6">
                  OR
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{ marginBottom: 2, justifyContent: "center" }}
            >
              {/* {meetingActionsToChoose?.length !== 0 && ( */}
                { customSMSToChoose?.length !==0 && (
                <FormControl style={{ width: "100%" }}>
                  <Select
                    value=""
                    displayEmpty
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        paddingBlock: 1,
                      },
                      justifyContent: "center",
                    }}
                    fullWidth
                  >
                    <MenuItem
                      value=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <em>Select Existing Template</em>
                    </MenuItem>
                    {/* {meetingActionsToChoose?.map((item) => ( */}
                      {customSMSToChoose?.map((item)=>(
                      <MenuItem
                        key={item._id}
                        value={item._id}
                        // onClick={() =>
                        //   handleAddFromExistingBookAMeetingActions(item._id)
                        // }
                        onClick={() =>
                          handleAddExistingBookAMeetingDialogBox(item)
                        }
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <span>{item?.data?.templateName}</span>
                          <span>
                            <Chip
                              sx={{ height: "25px" }}
                              label={item?.data?.communicationChannelInfo?.channel?.toUpperCase()}
                              color="primary"
                              variant="outlined"
                            />
                          </span>
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            maxWidth: "1000px",
            width: "900px",
          },
        }}
        open={selectedMsgTemplateOpen}
        onClose={() => setSelectedMsgTemplateOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <IconButton
          aria-label="close"
          onClick={() => setSelectedMsgTemplateOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid item xs={12}>
            <Grid container item xs={12}>
              <Grid item xs={12} mb={2}>
                <Typography align="center" variant="h6" fontWeight="bold">
                  Template Preview
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                mb={1}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Grid item xs={4} display="flex" alignItems="center" pl={1}>
                  <Typography
                    display="flex"
                    alignItems="flex-end"
                    variant="subtitle1"
                  >
                    Template Name :
                    <CustomHintPopover
                      size="small"
                      maxWidth={400}
                      transformHorizontalPosition={"left"}
                      transformVerticalPosition={"center"}
                      anchorHorizontalPosition={"right"}
                      anchorVerticalPosition={"top"}
                      hintContent={"The name  assigned to this template."}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="subtitle1"
                    sx={{ paddingInline: 1 }}
                    color="text.primary"
                  >
                    {selectedTemplate?.data?.templateName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                mb={1}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {/* <Grid item xs={4} display="flex" alignItems="center" pl={1}>
                  <Typography
                    display="flex"
                    alignItems="flex-end"
                    variant="subtitle1"
                  >
                    Meeting Link :
                    <CustomHintPopover
                      size="small"
                      maxWidth={400}
                      transformHorizontalPosition={"left"}
                      transformVerticalPosition={"center"}
                      anchorHorizontalPosition={"right"}
                      anchorVerticalPosition={"top"}
                      hintContent={
                        "The link that users will use to schedule their meeting. This is automatically appended to the Message Body."
                      }
                    />
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="subtitle1"
                    sx={{ paddingInline: 1 }}
                    color="text.primary"
                  >
                    {
                      selectedTemplate?.data?.communicationChannelInfo
                        ?.meetingLink
                    }
                  </Typography>
                </Grid> */}
              </Grid>
              <Grid
                item
                xs={12}
                mb={1}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Grid item xs={4} display="flex" pl={1}>
                  <Typography
                    display="flex"
                    alignItems="flex-end"
                    variant="subtitle1"
                  >
                    Message Content:
                    <CustomHintPopover
                      size="small"
                      maxWidth={400}
                      transformHorizontalPosition={"left"}
                      transformVerticalPosition={"center"}
                      anchorHorizontalPosition={"right"}
                      anchorVerticalPosition={"top"}
                      hintContent={"The text that will be sent in the SMS."}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="subtitle1"
                    sx={{ paddingInline: 1 }}
                    color="text.primary"
                  >
                    {
                      selectedTemplate?.data?.communicationChannelInfo
                        ?.messageBody
                    }
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                mb={1}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Grid item xs={4} display="flex" pl={1}>
                  <Typography
                    display="flex"
                    alignItems="flex-end"
                    variant="subtitle1"
                  >
                    Triggering Phrases:
                    <CustomHintPopover
                      size="small"
                      maxWidth={400}
                      transformHorizontalPosition={"left"}
                      transformVerticalPosition={"center"}
                      anchorHorizontalPosition={"right"}
                      anchorVerticalPosition={"top"}
                      hintContent={
                        "Specific phrases that, when mentioned during a call, will trigger the flow."
                      }
                    />
                  </Typography>
                  
                </Grid>
                
                
                
                <Grid item xs={8}>
                  <Typography
                    variant="subtitle1"
                    sx={{ paddingInline: 1 }}
                    color="text.primary"
                  >
                    {selectedTemplate?.triggerPrompts?.map((prompt, index) => (
                      <Grid
                        item
                        xs={10}
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="subtitle1" color="text.primary">
                          {`${index + 1 + ""}. ${prompt}`}
                        </Typography>
                      </Grid>
                    ))}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center" pl={1}>
                  <Typography
                    display="flex"
                    alignItems="flex-end"
                    variant="subtitle1"
                  >
                    Confirmation Message
                    <CustomHintPopover
                      size="small"
                      maxWidth={400}
                      transformHorizontalPosition={"left"}
                      transformVerticalPosition={"center"}
                      anchorHorizontalPosition={"right"}
                      anchorVerticalPosition={"top"}
                      hintContent={
                        "Ask if the recipient is interested in receiving the SMS message."                    
                      }
                    />
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="subtitle1"
                    sx={{ paddingInline: 1 }}
                    color="text.primary"
                  >
                    {
                      selectedTemplate?.data?.communicationChannelInfo
                        ?.consentMessage
                    }
                  </Typography>
                </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box mt={2}>
                <Grid container spacing={2} mb={1}>
                  <Grid item xs={6}>
                    <Button
                      style={{ minWidth: "120px" }}
                      variant="outlined"
                      onClick={() => {
                        setSelectedMsgTemplateOpen(false);
                      }}
                      fullWidth
                    >
                      <Typography variant="body2" textTransform="none">
                        Cancel
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      style={{ minWidth: "120px" }}
                      variant="contained"
                      onClick={() =>
                        handleAddFromExistingBookAMeetingActions(
                          selectedTemplate?.id
                        )
                      }
                      color="primary"
                      fullWidth
                    >
                      <Typography variant="body2" textTransform="none" sx={{color: "themetext.primary"}}>
                        Apply
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AssistantFunctions;
