import React, { useEffect, useMemo, useState } from "react";
import * as _ from "lodash";
import {
  Grid,
  Typography,
  Box,
  Switch,
  useTheme,
  IconButton,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Card,
  TextField,
  InputAdornment,
  keyframes,
  CardHeader,
  Chip,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormControl from "@mui/material/FormControl";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  postCreateAction,
  deleteAction,
  getAllActions,
  updateAssistant,
  updateAction,
} from "../../../../data/store/assistantsSlice";
import { isValidUrl, log } from "../../../../data/configs/utils";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CustomHintPopover from "../../../CustomHintPopover";
import { enqueueSnackbar } from "notistack";
import calImg from "../../../../data/assets/img/cal_logo.png";
import SchedulerCards from "../../../SchedulerCards";
import BookAMeetingFormDialog from "./BookAMeetingFormDialog";
import BookAMeetingMsg from "../../../BookAMeetingMsg";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";

const pop = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const BookAMeeting = ({
  saveChanges,
  setSaveChanges,
  handleSaveChangesUpdate,
  handleSaveChanges,
  allActionData,
  setAllActionData,
  handleChange,
  isChanged,
  assistant,
  initialBookAMeetingTemplate,
  setInititalBookAMeetingTemplate,
  actionIds,
  setActionIds,
  formData,
  setFormData,
  defaultFormData,
  formDataToUpdate,
  setFormDataToUpdate,
}) => {
  const [bookAMeetingActionData, setBookAMeetingActionData] = useState([]);
  const [deletingParticularMsgTemplate, setDeletingParticularMsgTemplate] =
    useState([]);
    const themeMode = useSelector((state) => state.pageDetails.themeMode);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [openAddNumberDialog, setOpenAddNumberDialog] = useState(false);
  const userQuota = useSelector((state) => state.authDetails?.user?.userQuota);
  const [showInput, setShowInput] = useState(false);
  const [isDeleteFromAllDialogOpen, setIsDeleteFromAllDialogOpen] =
    useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [assistantAppointmentScheduling, setAssistantAppointmentScheduling] =
    useState(assistant?.appointmentScheduling ?? false);
  const [selectedOpen, setSelectedOpen] = useState(false);
  const [currentMsgTemplateId, setCurrentMsgTemplateId] = useState("");
  const [selectedMsgTemplateOpen, setSelectedMsgTemplateOpen] = useState(false);
  const [agentQuota, setAgentQuota] = useState(
    userQuota?.find((quota) => quota.label === "Agents")?.remaining ?? 0
  );
  const [filteredBookAMeetingActionData, setFilteredBookAMeetingActionData] =
    useState([]);
  const [triggerPrompts, setTriggerPrompts] = useState([
    "I'm interested in scheduling a meeting.",
  ]);
  const [editMsgTemplate, setEditMsgTemplate] = useState(false);

  const handleAddDeleteTriggerPrompts = (operation, value) => {
    if (!operation || !value) return;

    const updatedValue = value.trim();
    const triggerPromptsArray = Array.isArray(triggerPrompts)
      ? triggerPrompts
      : [];
    const doesExist = triggerPromptsArray.includes(updatedValue);

    if (operation === "add" && !doesExist) {
      setTriggerPrompts([updatedValue, ...triggerPromptsArray]);
    } else if (operation === "delete") {
      const updatedTriggerPrompts = triggerPromptsArray.filter(
        (val) => val !== updatedValue
      );
      setTriggerPrompts(updatedTriggerPrompts);
    }
  };
  // const handleAddDeleteTriggerPrompts = (operation, value) => {
  //   if (!operation && !value) return;
  //   const updatedValue = value?.trim();
  //   const doesExist = triggerPrompts?.includes(updatedValue);
  //   if (operation === "add" && !doesExist) {
  //     setTriggerPrompts([updatedValue, ...triggerPrompts]);
  //   } else if (operation === "delete") {
  //     const updatedTriggerPromts = triggerPrompts?.filter(
  //       (value) => value !== updatedValue
  //     );
  //     setTriggerPrompts(updatedTriggerPromts);
  //   }
  // };
  const handleFormDataChange = (parent, name, value) => {
    if (parent === "root") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else
      setFormData((prevData) => ({
        ...prevData,
        [parent]: {
          ...prevData[parent],
          [name]: value,
        },
      }));
  };
  //for initailly rendering the template on UI after first render
  const handleExtractAssistantAllActions = () => {
    const actions = assistant?.actions || [];
    actions.forEach((action) => {
      if (
        action &&
        action?.data &&
        action?._id &&
        action?.type === "custom_sms"
      ) {
        setInititalBookAMeetingTemplate([
          {
            id: action._id,
            data: { ...action.data },
            triggerPrompts: action.triggerPrompts,
          },
        ]);
      }
    });
  };

  const handleAddExistingMsgTemplate = () => {
    if (assistant?.appointmentScheduling === false)
      handleChange({ appointmentScheduling: true });
    setInititalBookAMeetingTemplate([selectedTemplate]);
    setCurrentMsgTemplateId(selectedTemplate?.id ?? "");
    setFormData({ ...selectedTemplate?.data } ?? defaultFormData);
    setTriggerPrompts(
      selectedTemplate?.triggerPrompts ?? [
        "I'm interested in scheduling a meeting.",
      ]
    );
    if (!actionIds?.includes(selectedTemplate?.id))
      actionIds.push(selectedTemplate?.id);
    handleChange({ actions: actionIds });
    setOpen(false);
    handleFilterAllActionData();
    filterData(selectedTemplate?.id);
    setSelectedMsgTemplateOpen(false);
    setOpenAddNumberDialog(false);
    handleSaveChangesUpdate("smsBooking", true);
  };

  const filterData = (id) => {
    const filteredData = bookAMeetingActionData.filter(
      (item) => item._id !== id
    );
    setFilteredBookAMeetingActionData(filteredData);
  };

  const handleFilterAllActionData = () => {
    const filteredData = allActionData?.filter(
      (item) => item.type === "appointment_scheduling"
    );
    setBookAMeetingActionData(filteredData);
    const filteredExistingData = filteredData?.filter(
      (item) => item._id !== initialBookAMeetingTemplate[0]?.id
    );
    setFilteredBookAMeetingActionData(filteredExistingData);
  };

  const handleDeleteWebhookDialog = (obj) => {
    setDeletingParticularMsgTemplate({
      url: obj.url ?? "url missing",
      id: obj.id ?? "id missing",
    });
    setOpen(true);
  };

  const handleDeleteMsgTemplatFromCurrent = (id) => {
    setInititalBookAMeetingTemplate([]);
    handleChange({ appointmentScheduling: false });
    const UpdateActionIds = actionIds.filter((actionId) => actionId !== id);
    setActionIds(UpdateActionIds);
    handleChange({ actions: UpdateActionIds });
    setAssistantAppointmentScheduling(false);
    setShowInput(false);
    handleSaveChangesUpdate("smsBooking", true);
    handleSetPayloadDataAndFormDataToInitialValue();
  };

  const handleSetPayloadDataAndFormDataToInitialValue = () => {
    setFormData(defaultFormData);
    setTriggerPrompts(["I'm interested in scheduling a meeting."]);
    setCurrentMsgTemplateId("");
  };

  const getAllActionData = () => {
    dispatch(getAllActions())
      .unwrap()
      .then((res) => {
        setAllActionData(res?.data?.actions);
        if (res.status === "success") {
          handleSaveChangesUpdate("smsBooking", true);
        }
      })
      .catch((err) => {
        log("ERR-getAllActions", err);
      });
  };

  useEffect(() => {
    if (saveChanges?.smsBooking) {
      handleSaveChanges();
      handleSaveChangesUpdate("smsBooking", false);
    }
  }, [saveChanges]);

  const trimStringFields = (obj) => {
    const newObj = Array.isArray(obj) ? [] : {};
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        newObj[key] = obj[key].trim();
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        newObj[key] = trimStringFields(obj[key]);
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };

  const createAction = (mode) => {
    const filteredData = { ...formData };
    if (mode === "communication") delete filteredData.calendarSpecificInfo;
    if (mode === "calendar") delete filteredData.communicationChannelInfo;

    // Trim all string fields in filteredData
    const trimedData = trimStringFields(filteredData);

    const payload = {
      name: "appointment",
      description: "Appointment Scheduling for user",
      type: "appointment_scheduling",
      data: {
        ...trimedData,
        mode,
      },
      triggerPrompts,
    };
    dispatch(postCreateAction({ ...payload }))
      .unwrap()
      .then((res) => {
        if (res && res.data && res.data.action && res.data.action._id) {
          actionIds.push(res.data.action._id);
          handleChange({ actions: actionIds });
          setInititalBookAMeetingTemplate([
            {
              id: res.data.action._id,
              data: { ...res.data.action.data },
              triggerPrompts: res.data.action.triggerPrompts,
            },
          ]);
          setFormDataToUpdate({
            id: res.data.action._id ?? "",
            formData: { ...res.data.action.data } ?? defaultFormData,
            triggeringPrompts: res.data.action.triggerPrompts ?? [
              "I'm interested in scheduling a meeting.",
            ],
          });
        }
        if (res?.status === "success") {
        }
        getAllActionData();
      })
      .catch((err) => {
        log("ERR-getAllActions", err);
      });
    handleAddWebhookDialogClose();
    setSelectedOpen(false);
  };

  const updatingAction = (mode) => {
    const filteredData = { ...formData };
    if (mode === "communication") delete filteredData.calendarSpecificInfo;
    if (mode === "calendar") delete filteredData.communicationChannelInfo;

    // Trim all string fields in filteredData
    const trimedData = trimStringFields(filteredData);

    const payload = {
      name: "appointment",
      description: "Appointment Scheduling for user",
      type: "appointment_scheduling",
      data: {
        ...trimedData,
        mode,
      },
      triggerPrompts,
      id: currentMsgTemplateId,
    };
    dispatch(updateAction({ ...payload }))
      .unwrap()
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.updatedAction &&
          res.data.updatedAction._id
        ) {
          actionIds.push(res.data.updatedAction._id);
          handleChange({ actions: actionIds });
          setInititalBookAMeetingTemplate([
            {
              id: res.data.updatedAction._id,
              data: { ...res.data.updatedAction.data },
              triggerPrompts: res.data.updatedAction.triggerPrompts,
            },
          ]);
          setFormDataToUpdate({
            id: res.data.updatedAction._id ?? "",
            formData: { ...res.data.updatedAction.data } ?? defaultFormData,
            triggeringPrompts: res.data.updatedAction.triggerPrompts ?? [
              "I'm interested in scheduling a meeting.",
            ],
          });
        }
        if (res?.status === "success") {
        }
        getAllActionData();
        setEditMsgTemplate(false);
      })
      .catch((err) => {
        log("ERR-getAllActions", err);
      });
    handleAddWebhookDialogClose();
    setSelectedOpen(false);
  };

  const handleDeleteAction = (id) => {
    dispatch(deleteAction({ id }))
      .unwrap()
      .then((res) => {
        setInititalBookAMeetingTemplate([]);
        handleChange({ appointmentScheduling: false });
        const UpdateActionIds = actionIds.filter((actionId) => actionId !== id);
        setActionIds(UpdateActionIds);
        handleChange({ actions: UpdateActionIds });
        setAssistantAppointmentScheduling(false);
        setShowInput(false);
        handleSetPayloadDataAndFormDataToInitialValue();
        getAllActionData();
      })
      .catch((err) => {
        log("ERR-getAllActions", err);
      });
  };

  const handleExtractAssistantBookingTemplateFromActions = (actions = []) => {
    const findTemplateDataFromAssistant = actions?.filter(
      (obj) => obj?.type === "appointment_scheduling"
    );

    if (findTemplateDataFromAssistant?.length > 0) {
      const updateAssistantTemplateObj = findTemplateDataFromAssistant[0];
      setFormDataToUpdate({
        id: updateAssistantTemplateObj?._id,
        formData: updateAssistantTemplateObj?.data,
        triggeringPrompts: updateAssistantTemplateObj?.triggerPrompts,
      });
    } else {
      setFormDataToUpdate({
        id: "",
        formData: defaultFormData,
        triggeringPrompts: ["I'm interested in scheduling a meeting."],
      });
    }
  };

  useEffect(() => {
    //temp fix checking id to prevent calling function
    if (assistant?.actions?.length > 0 && assistant?.actions?.[0]?._id) {
      handleExtractAssistantBookingTemplateFromActions(assistant?.actions);
    }
  }, [assistant]);

  const handleEditMsgTemplate = () => {
    setSelectedOpen(true);
    setEditMsgTemplate(true);
    setCurrentMsgTemplateId(formDataToUpdate?.id);
    setFormData(formDataToUpdate?.formData);
    setTriggerPrompts(formDataToUpdate?.triggeringPrompts);
  };

  const handleAddExistingBookAMeetingDialogBox = (item) => {
    setCurrentMsgTemplateId(item?._id ?? "");
    setSelectedTemplate({
      id: item._id,
      data: { ...item.data },
      triggerPrompts: item.triggerPrompts,
    });
    setSelectedMsgTemplateOpen(true);
  };

  const handleWebhookAccordianToggle = (value) => {
    // if (
    //   initialBookAMeetingTemplate?.length > 0 &&
    //   value === true &&
    //   assistant?.appointmentScheduling !== true
    // )
    //   handleChange({ appointmentScheduling: value });
    // else if (value === false && assistant?.appointmentScheduling !== false) {
    //   handleChange({ appointmentScheduling: value });
    // }
    // //check and correct it only allo if key is not present
    // else if (!assistant?.appointmentScheduling) {
    //   handleChange({ appointmentScheduling: value });
    // }
    setAssistantAppointmentScheduling(value);
  };

  //this useEffect is used for updating the finititalBookAMeetingTemplate state after initial/allActionData updated
  useEffect(() => {
    handleExtractAssistantAllActions();
    // handleFilterAllActionData();
  }, [allActionData]);

  //this useEffect is used for updating the filteredExistingData after initialBookAMeetingTemplate updated its state
  useEffect(() => {
    handleFilterAllActionData();
  }, [initialBookAMeetingTemplate, allActionData]);

  const handleToggleDialog = () => {
    setOpen(!open);
  };

  const handleAddWebhookDialogClose = () => {
    setSelectedMsgTemplateOpen(false);
    setOpenAddNumberDialog(false);
    setShowInput(false);
  };

  return (
    <Grid container xs={12} justifyContent="center" marginTop={1}>
      <Accordion
        defaultExpanded
        expanded={assistantAppointmentScheduling}
        style={{
          width: "100%",
          borderRadius: "20px",
          boxShadow: "0px 0px 1px 0px",
        }}
      >
        <AccordionSummary>
          <Grid
            xs={12}
            item
            container
            sx={{ height: "40px" }}
            alignItems="center"
            border={0}
            borderRadius={1}
            borderColor={theme.palette.divider}
            paddingInline={1}
          >
            <Grid item xs={6}>
              <Typography
                display="flex"
                variant="subtitle1"
                fontWeight={"bold"}
                alignItems="center"
              >
                Book A Meeting
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"center"}
                  anchorHorizontalPosition={"right"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    <>
                      <p>
                        This feature ensures a smooth and efficient way to book
                        your meetings, making the process as seamless and
                        convenient as possible.
                      </p>
                      <br />
                      <p>
                        <strong>SMS Booking</strong>
                      </p>
                      <ul>
                        <li>
                          <p>
                            <strong>What it Does:</strong> On a call, if a user
                            wants to book a meeting, they will receive an SMS
                            with a scheduling link.
                          </p>
                        </li>
                        <br />
                        <li>
                          <p>
                            <strong>Configuration :</strong> Customize the
                            message body, provide the scheduling link, and set
                            specific phrases (trigger prompts) that will
                            automatically initiate the SMS booking flow during
                            the call.
                          </p>
                        </li>
                      </ul>
                      <br />
                      <p>
                        <strong>
                          Note: This feature is currently in Beta.
                        </strong>
                      </p>
                    </>
                  }
                />
                <CustomHintPopover
                  icon={
                    <Chip
                      size="small"
                      label="Beta"
                      color="primary"
                      sx={{
                        borderRadius: "16px",
                        paddingBloack: 0,
                      }}
                    />
                  }
                  showIcon={false}
                  size="small"
                  maxWidth={400}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"center"}
                  anchorHorizontalPosition={"right"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    <>
                      <p>
                        This feature ensures a smooth and efficient way to book
                        your meetings, making the process as seamless and
                        convenient as possible.
                      </p>
                      <br />
                      <p>
                        <strong>SMS Booking</strong>
                      </p>
                      <ul>
                        <li>
                          <p>
                            <strong>What it Does:</strong> On a call, if a user
                            wants to book a meeting, they will receive an SMS
                            with a scheduling link.
                          </p>
                        </li>
                        <br />
                        <li>
                          <p>
                            <strong>Configuration :</strong> Customize the
                            message body, provide the scheduling link, and set
                            specific phrases (trigger prompts) that will
                            automatically initiate the SMS booking flow during
                            the call.
                          </p>
                        </li>
                      </ul>
                      <br />
                      <p>
                        <strong>
                          Note: This feature is currently in Beta.
                        </strong>
                      </p>
                    </>
                  }
                />
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <Switch
                defaultChecked={assistant?.appointmentScheduling}
                checked={assistantAppointmentScheduling}
                inputProps={{ "aria-label": "controlled" }}
                onChange={(event) => {
                  handleWebhookAccordianToggle(event.target.checked);
                }}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container item xs={12}>
            <Grid xs={12} item container paddingInline={4}>
              {initialBookAMeetingTemplate?.length > 0 ? (
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  {initialBookAMeetingTemplate?.map((obj) => (
                    <Grid item xs={12} key={obj}>
                      <Card
                        sx={{
                          padding: 1,
                          borderRadius: "10px",
                          bgcolor: "#d8e7ff",
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          paddingInline={2}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            mt={1}
                            sx={{
                              position: "relative",
                              display: "flex",
                              placeContent: "center",
                            }}
                          >
                            <Grid item>
                              <Typography
                                align="end"
                                fontWeight="bold"
                                sx={{ fontSize: "subtitle1", paddingInline: 1 }}
                                color="text.primary"
                              >
                                SMS Booking
                              </Typography>
                            </Grid>
                            <Grid
                              borderLeft="4px solid #acb8cc"
                              item
                              marginTop={3}
                              marginBottom={1}
                              xs={12}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              bgcolor="#d8e7ff"
                            >
                              <Typography
                                variant="subtitle2"
                                paragraph="none"
                                mb={0}
                                paddingInline={1}
                                color={"black"}
                              >
                                During a call, if the user says any of the
                                <strong>
                                  specific trigger prompts (e.g., "I'm
                                  interested in scheduling a meeting" or "Can we
                                  set up an appointment?")
                                </strong>
                                , an <strong>SMS</strong> will be sent using the
                                <strong>message body and meeting link</strong>
                                you've set up in the template.
                                <strong>
                                  Trigger prompts can be customized to suit your
                                  needs.
                                </strong>
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              item
                              sx={{
                                top: "0px",
                                position: "absolute",
                                right: "0px",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "start",
                              }}
                            >
                              <Grid item paddingLeft={1}>
                                <EditIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleEditMsgTemplate();
                                  }}
                                  sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                      animation: `${pop} 0.3s ease-in-out forwards`,
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item paddingInline={1}>
                                <DeleteIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setOpen(true);
                                  }}
                                  aria-label="delete"
                                  sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                      animation: `${pop} 0.3s ease-in-out forwards`,
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            mb={1}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "start",
                            }}
                          >
                            <Grid
                              item
                              xs={3}
                              display="flex"
                              alignItems="center"
                              pl={1}
                            >
                              <Typography
                                display="flex"
                                alignItems="flex-end"
                                variant="subtitle1"
                              >
                                Template Name :
                                <CustomHintPopover
                                  size="small"
                                  maxWidth={400}
                                  transformHorizontalPosition={"left"}
                                  transformVerticalPosition={"center"}
                                  anchorHorizontalPosition={"right"}
                                  anchorVerticalPosition={"top"}
                                  hintContent={
                                    "The name  assigned to this template."
                                  }
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <Typography
                                variant="subtitle1"
                                fontWeight="bolder"
                                sx={{ paddingInline: 1 }}
                                color="text.primary"
                              >
                                {obj?.data?.templateName}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            mb={1}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "start",
                            }}
                          >
                            <Grid
                              item
                              xs={3}
                              display="flex"
                              alignItems="center"
                              pl={1}
                            >
                              <Typography
                                display="flex"
                                alignItems="flex-end"
                                variant="subtitle1"
                              >
                                Meeting Link :
                                <CustomHintPopover
                                  size="small"
                                  maxWidth={400}
                                  transformHorizontalPosition={"left"}
                                  transformVerticalPosition={"center"}
                                  anchorHorizontalPosition={"right"}
                                  anchorVerticalPosition={"top"}
                                  hintContent={
                                    "The link that users will use to schedule their meeting. This is automatically appended to the Message Body."
                                  }
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <Typography
                                border="1px solid black"
                                borderRadius="24px"
                                variant="subtitle1"
                                sx={{ paddingInline: 2, paddingBlock: 0.5 }}
                                color="text.primary"
                              >
                                {
                                  obj?.data?.communicationChannelInfo
                                    ?.meetingLink
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            mb={1}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "start",
                            }}
                          >
                            <Grid item xs={3} display="flex" pl={1}>
                              <Typography
                                display="flex"
                                alignItems="flex-end"
                                variant="subtitle1"
                              >
                                Message Body :
                                <CustomHintPopover
                                  size="small"
                                  maxWidth={400}
                                  transformHorizontalPosition={"left"}
                                  transformVerticalPosition={"center"}
                                  anchorHorizontalPosition={"right"}
                                  anchorVerticalPosition={"top"}
                                  hintContent={
                                    "The text that will be sent in the SMS."
                                  }
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <Typography
                                border="1px solid black"
                                borderRadius="24px"
                                variant="subtitle1"
                                sx={{ paddingInline: 2, paddingBlock: 0.5 }}
                                color="text.primary"
                              >
                                {
                                  obj?.data?.communicationChannelInfo
                                    ?.messageBody
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            mb={1}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "start",
                            }}
                          >
                            <Grid item xs={3} pl={1}>
                              <Typography
                                display="flex"
                                alignItems="flex-end"
                                variant="subtitle1"
                              >
                                Triggering Prompts :
                                <CustomHintPopover
                                  size="small"
                                  maxWidth={400}
                                  transformHorizontalPosition={"left"}
                                  transformVerticalPosition={"center"}
                                  anchorHorizontalPosition={"right"}
                                  anchorVerticalPosition={"top"}
                                  hintContent={
                                    " Specific phrases that, when mentioned during a call, will trigger the flow."
                                  }
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <Typography
                                variant="subtitle1"
                                sx={{ paddingInline: 1 }}
                                color="text.primary"
                              >
                                {obj?.triggerPrompts?.map((prompt, index) => (
                                  <Grid
                                    item
                                    xs={10}
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      color="text.primary"
                                    >
                                      {`${index + 1 + ""}. ${prompt}`}
                                    </Typography>
                                  </Grid>
                                ))}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : null}

              <Grid item xs={12} align="start" marginTop={-2}>
                <Button
                  onClick={() => {
                    if (
                      filteredBookAMeetingActionData?.length === 0 &&
                      initialBookAMeetingTemplate?.length === 0
                    ) {
                      setSelectedOpen(true);
                    } else {
                      setOpenAddNumberDialog(true);
                    }
                  }}
                  variant="contained"
                  //   startIcon={<AddIcon />}
                  disabled={initialBookAMeetingTemplate?.length >= 2}
                  sx={{
                    width: "200px",
                    marginBlock: 4,
                    borderRadius: "10px !important",
                  }}
                >
                  {initialBookAMeetingTemplate?.length === 0
                    ? "Add Template"
                    : "Change Template"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Dialog
        PaperProps={{
          style: {
            maxWidth: "1400px",
          },
        }}
        open={openAddNumberDialog}
        onClose={handleAddWebhookDialogClose}
      >
        <DialogTitle>
          <Typography align="center" variant="h6" fontWeight="bold">
            Add Template
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleAddWebhookDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ minWidth: "550px" }}>
            <Grid item xs={12} align="center" mt={2} mb={2}>
              <Tooltip
                title={
                  initialBookAMeetingTemplate?.length >= 1
                    ? "You can add maximum 1 MSG Template. Delete current to add another."
                    : ""
                }
                arrow
                placement="top"
              >
                <span>
                  <Button
                    style={{
                      minWidth: "300px",
                      paddingBlock: 4,
                      paddingInline: 15,
                    }}
                    variant="contained"
                    disabled={initialBookAMeetingTemplate?.length >= 1}
                    onClick={() => setSelectedOpen(true)}
                  >
                    <Typography
                      align="center"
                      variant="body1"
                      textTransform="none"
                      sx={{ color: "themetext.primary"}}
                    >
                      SMS Meeting Booking
                    </Typography>
                  </Button>
                </span>
              </Tooltip>
            </Grid>
            {filteredBookAMeetingActionData?.length !== 0 && (
              <Grid item xs={12} align="center" mt={2} mb={2}>
                <Typography align="center" fontWeight="bold" variant="h6">
                  OR
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{ marginBottom: 2, justifyContent: "center" }}
            >
              {filteredBookAMeetingActionData?.length !== 0 && (
                <FormControl style={{ width: "100%" }}>
                  <Select
                    value=""
                    displayEmpty
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        paddingBlock: 1,
                      },
                      justifyContent: "center",
                    }}
                    fullWidth
                  >
                    <MenuItem
                      value=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <em>Select Existing Template</em>
                    </MenuItem>
                    {filteredBookAMeetingActionData?.map((item) => (
                      <MenuItem
                        key={item._id}
                        value={item._id}
                        onClick={() =>
                          handleAddExistingBookAMeetingDialogBox(item)
                        }
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <span>{item?.data?.templateName}</span>
                          <span>
                            <Chip
                              sx={{ height: "25px" }}
                              label={item?.data?.communicationChannelInfo?.channel?.toUpperCase()}
                              color="primary"
                              variant="outlined"
                            />
                          </span>
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <Typography align="center" variant="h6" fontWeight="bold">
            Delete Template
          </Typography>

          <IconButton
            aria-label="close"
            onClick={handleToggleDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" mb={1}>
            Are you sure you want to delete this SMS Template
            <strong>
              {initialBookAMeetingTemplate?.[0]?.data?.templateName}
            </strong>
            ?
          </Typography>
          <Box mt={2}>
            <Grid container spacing={2} mb={2} mt={1}>
              <Grid item xs={6}>
                <Tooltip
                  title={`This action will delete this SMS Template ${initialBookAMeetingTemplate?.[0]?.data?.templateName} from the current assistant`}
                >
                  <Button
                    style={{ minWidth: "200px", paddingBlock: 12 }}
                    variant="contained"
                    onClick={() => {
                      handleDeleteMsgTemplatFromCurrent(
                        initialBookAMeetingTemplate?.[0]?.id
                      );
                      handleToggleDialog();
                    }}
                    fullWidth
                    endIcon={<DeleteIcon />}
                  >
                    <Typography variant="body2" textTransform="none">
                      From Current Assistant
                    </Typography>
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip
                  title={`This action will delete this SMS Template ${initialBookAMeetingTemplate?.[0]?.data?.templateName} from the all assistant`}
                >
                  <Button
                    style={{ minWidth: "200px", paddingBlock: 12 }}
                    variant="contained"
                    onClick={() => {
                      handleToggleDialog();
                      setIsDeleteFromAllDialogOpen(true);
                    }}
                    color="error"
                    fullWidth
                    endIcon={<DeleteIcon />}
                  >
                    <Typography variant="body2" textTransform="none">
                      From All Assistants
                    </Typography>
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeleteFromAllDialogOpen}
        onClose={() => setIsDeleteFromAllDialogOpen(false)}
      >
        <DialogTitle>
          <Typography align="center" variant="h6" fontWeight="bold">
            Delete Template From All Assistants
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setIsDeleteFromAllDialogOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} align="center">
            <Typography variant="body1">
              Are you sure you want to delete this SMS Template
              {initialBookAMeetingTemplate?.[0]?.data?.templateName} from all
              assistants?
            </Typography>
          </Grid>
          <Box mt={2}>
            <Grid item xs={12} align="center">
              <Button
                style={{
                  minWidth: "200px",
                  paddingBlock: 12,
                  paddingInline: 15,
                }}
                variant="contained"
                onClick={() => {
                  handleDeleteAction(initialBookAMeetingTemplate?.[0]?.id);
                  setIsDeleteFromAllDialogOpen(false);
                }}
                color="error"
                endIcon={<DeleteIcon />}
              >
                <Typography align="center" variant="body1" textTransform="none">
                  Confirm Delete
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} align="center" mt={2}>
              <Typography variant="caption">
                This action will remove this
                {initialBookAMeetingTemplate?.[0]?.data?.templateName} Template
                from all the assistant you have.
              </Typography>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Dialog
            PaperProps={{
              style: {
                maxWidth: "1000px",
                width: "900px",
              },
            }}
            open={selectedOpen}
            onClose={() => {
              setSelectedOpen(false);
              setEditMsgTemplate(false);
              handleSetPayloadDataAndFormDataToInitialValue();
            }}
            maxWidth="sm"
            fullWidth
          >
            <DialogContent>
              <BookAMeetingMsg
                handleSetPayloadDataAndFormDataToInitialValue={
                  handleSetPayloadDataAndFormDataToInitialValue
                }
                setEditMsgTemplate={setEditMsgTemplate}
                setSelectedOpen={setSelectedOpen}
                updatingAction={updatingAction}
                editMsgTemplate={editMsgTemplate}
                handleAddDeleteTriggerPrompts={handleAddDeleteTriggerPrompts}
                triggerPrompts={triggerPrompts}
                createAction={createAction}
                handleFormDataChange={handleFormDataChange}
                formData={formData}
                setFormData={setFormData}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            PaperProps={{
              style: {
                maxWidth: "1000px",
                width: "900px",
              },
            }}
            open={selectedMsgTemplateOpen}
            onClose={() => setSelectedMsgTemplateOpen(false)}
            maxWidth="sm"
            fullWidth
          >
            <IconButton
              aria-label="close"
              onClick={() => setSelectedMsgTemplateOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Grid item xs={12}>
                <Grid container item xs={12}>
                  <Grid item xs={12} mb={2}>
                    <Typography align="center" variant="h6" fontWeight="bold">
                      Template Preview
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    mb={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={4} display="flex" alignItems="center" pl={1}>
                      <Typography
                        display="flex"
                        alignItems="flex-end"
                        variant="subtitle1"
                      >
                        Template Name :
                        <CustomHintPopover
                          size="small"
                          maxWidth={400}
                          transformHorizontalPosition={"left"}
                          transformVerticalPosition={"center"}
                          anchorHorizontalPosition={"right"}
                          anchorVerticalPosition={"top"}
                          hintContent={"The name  assigned to this template."}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="subtitle1"
                        sx={{ paddingInline: 1 }}
                        color="text.primary"
                      >
                        {selectedTemplate?.data?.templateName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    mb={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={4} display="flex" alignItems="center" pl={1}>
                      <Typography
                        display="flex"
                        alignItems="flex-end"
                        variant="subtitle1"
                      >
                        Meeting Link :
                        <CustomHintPopover
                          size="small"
                          maxWidth={400}
                          transformHorizontalPosition={"left"}
                          transformVerticalPosition={"center"}
                          anchorHorizontalPosition={"right"}
                          anchorVerticalPosition={"top"}
                          hintContent={
                            "The link that users will use to schedule their meeting. This is automatically appended to the Message Body."
                          }
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="subtitle1"
                        sx={{ paddingInline: 1 }}
                        color="text.primary"
                      >
                        {
                          selectedTemplate?.data?.communicationChannelInfo
                            ?.meetingLink
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    mb={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={4} display="flex" pl={1}>
                      <Typography
                        display="flex"
                        alignItems="flex-end"
                        variant="subtitle1"
                      >
                        Message Body :
                        <CustomHintPopover
                          size="small"
                          maxWidth={400}
                          transformHorizontalPosition={"left"}
                          transformVerticalPosition={"center"}
                          anchorHorizontalPosition={"right"}
                          anchorVerticalPosition={"top"}
                          hintContent={"The text that will be sent in the SMS."}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="subtitle1"
                        sx={{ paddingInline: 1 }}
                        color="text.primary"
                      >
                        {
                          selectedTemplate?.data?.communicationChannelInfo
                            ?.messageBody
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    mb={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={4} display="flex" pl={1}>
                      <Typography
                        display="flex"
                        alignItems="flex-end"
                        variant="subtitle1"
                      >
                        Triggering Prompts :
                        <CustomHintPopover
                          size="small"
                          maxWidth={400}
                          transformHorizontalPosition={"left"}
                          transformVerticalPosition={"center"}
                          anchorHorizontalPosition={"right"}
                          anchorVerticalPosition={"top"}
                          hintContent={
                            "Specific phrases that, when mentioned during a call, will trigger the flow."
                          }
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="subtitle1"
                        sx={{ paddingInline: 1 }}
                        color="text.primary"
                      >
                        {selectedTemplate?.triggerPrompts?.map(
                          (prompt, index) => (
                            <Grid
                              item
                              xs={10}
                              key={index}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                color="text.primary"
                              >
                                {`${index + 1 + ""}. ${prompt}`}
                              </Typography>
                            </Grid>
                          )
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box mt={2}>
                    <Grid container spacing={2} mb={1}>
                      <Grid item xs={6}>
                        <Button
                          style={{ minWidth: "120px" }}
                          variant="outlined"
                          onClick={() => {
                            setSelectedMsgTemplateOpen(false);
                          }}
                          fullWidth
                        >
                          <Typography variant="body2" textTransform="none">
                            Cancel
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          style={{ minWidth: "120px" }}
                          variant="contained"
                          onClick={handleAddExistingMsgTemplate}
                          color="primary"
                          fullWidth
                        >
                          <Typography variant="body2" textTransform="none">
                            Apply
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BookAMeeting;
